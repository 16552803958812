

















import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { Environment } from "@/services/Environment";
import { getModule } from "vuex-module-decorators";
import {
  TransactionModule,
  NewTransaction
} from "@shared/store/modules/TransactionModule";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";

import { namespace } from "vuex-class";
import axios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  confirmed,
  email,
  numeric,
  min
} from "vee-validate/dist/rules";
//const transactionModule = namespace('TransactionModule')
import BTextInputWithValidation from "@shared/components/Inputs/BTextInputWithValidation.vue";
import CreateTransaction from "./CreateTransaction.vue";
@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BTextInputWithValidation,
    CreateTransaction
  }
})
export default class Request1 extends Vue {
  public isLoading: boolean = false;
  public error: string | null = null;

  @Prop({default:''})
  public mode!:string;

  public onCreated() {
    this.$router.push("/Request2");
  }
  public mounted() {
    document.body.style.backgroundColor = "transparent";
    try {
      parent.postMessage("Normal", "*");
    } catch (ex) {
      console.log("not running in iframe");
      console.log(ex);
    }
  }

  public destroy() {
    document.body.style.backgroundColor = "";
  }
}
